import { useEffect, useState } from "react";
import NavBar from "./components/NavBar/NavBar";
import TopBar from "./components/TopBar";
import { HamburgerContext } from "./contexts/HamburgerContext";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import Causes from "./pages/Causes";
import News from "./pages/News";
import CausesDetail from "./pages/CausesDetail";
import ContactUs from "./pages/ContactUs";
import Donate from "./pages/Donate";
import NewsDetail from "./pages/NewsDetail";
import useFetch from "./hooks/useFetch";
import { DataContext } from "./contexts/DataContext";
import { url_get_causes, url_get_news } from "./Constants";
import Vision from "./pages/Vision";
function App() {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false);
  const [causes,setCauses] = useState([])
  const [news,setNews] = useState([])

  const {data:newsData} = useFetch(url_get_news)
  const {data:causesData} = useFetch(url_get_causes)
  useEffect(()=>{
    setCauses(causesData)
    setNews(newsData)
  },[causesData,newsData])
  return (
    <div className="App">
     
     
      <DataContext.Provider value={{causes,news}}>
      <HamburgerContext.Provider
        value={{ hamburgerIsActive, setHamburgerIsActive }}
      >
        <TopBar />
        <NavBar />
      </HamburgerContext.Provider>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/causes" element={<Causes/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/news/:newsId" element={<NewsDetail/>}/>
        <Route path="/causes/:causeId" element={<CausesDetail/>}/>
        <Route path="/contactus" element={<ContactUs/>}/>
        <Route path="/donate" element={<Donate/>}/>
        <Route path="/vision" element={<Vision/>}/>
      </Routes>
      </DataContext.Provider>
      <Footer />
    </div>
  );
}

export default App;
