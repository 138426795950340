import React from 'react'
import PrimaryButton from '../PrimaryButton'
import { SwiperSlide1Container } from './SwiperSlideStyle'
import { useNavigate } from 'react-router-dom'

function SwiperSlide1(props) {
  const navigate = useNavigate()
  return (
    <SwiperSlide1Container $backgroundimage={props.backgroundImage}>
        {props.children}
        <PrimaryButton onClick={()=>navigate('/donate')}>
            <h3>DONATE NOW</h3>
        </PrimaryButton>
    </SwiperSlide1Container>
  )
}

export default SwiperSlide1