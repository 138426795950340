import styled from "styled-components";

export const NewsCardContainer = styled.div`
    width: 22rem;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    cursor: pointer;
    &:hover{
        >img{
            scale: 1.05;
        }
    }

    >img{
        transition: scale 200ms ease-in-out;
        width: 22rem;
    }
    >button{
        color: black;
        width: 11rem;
        padding: 0.5rem 1rem;
    }
    >p{
        font-weight: 300;
        font-size: 1rem;

    }
    >h3{
        font-weight: 500;
        font-size: 1.2rem;
    }
`