import NewsCard from "../components/NewsCard"
import { NewsContainer } from "./NewsStyle"
import HeadingText from "../components/HeadingText"
import PageHeader from "../components/PageHeader"
import demoImage from '../assets/images/kid.jpg'
import { useContext } from "react"
import { DataContext } from "../contexts/DataContext"
function News() {
  const {news} = useContext(DataContext)
  return (
    <NewsContainer>
      <PageHeader pageTitle={"News"} path={"HOME / NEWS"} backgroundImage={demoImage}/>
         <HeadingText>
            <h3>Stay up-to-date with our activities.<span>Our News</span></h3>
        </HeadingText>
        <div className="news-grid">
            {news?.map((newsItem,index)=>{
              return <NewsCard key={index} news={newsItem}/>
            })}
        </div>
    </NewsContainer>
  )
}

export default News