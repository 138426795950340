import styled from "styled-components";


export const PageHeaderContainer = styled.div`
        color: white;
        background-image: linear-gradient(rgba(0,0,0,.45),rgba(0,0,0,.45)),url(${({$backgroundimage})=>$backgroundimage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 40%;
        height: clamp(6rem,5vh,8rem);
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        width: 100%;
        height: max-content;
        box-sizing: border-box;
    >h2{
        font-size: clamp(1.5rem,3vw,2rem);
        font-weight: 500;
        margin-bottom: .5rem;
    }
    >P{
        margin-top: 0;
        font-size: clamp(0.6rem,2vw,0.8rem);
        font-weight: 300;
        color: rgba(255,255,255,.7);
    }

`