import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {Autoplay } from 'swiper/modules'
import SwiperCore from "swiper";
import SwiperSlide1 from "./SwiperSlide1";
import backgroundImage2 from '../../assets/images/twogirls.jpg'
import backgroundImage1 from '../../assets/images/kidgroup.jpg'

SwiperCore.use([Autoplay]);
function HomeSwiper() {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      style={{width:"100%"}}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <SwiperSlide1 backgroundImage={backgroundImage1}>
          <h2>DONATE AND HELP</h2>
          <p>Raise Funds for ethiopian women, children and thier education.</p>
        </SwiperSlide1>
      </SwiperSlide>
      <SwiperSlide>
        <SwiperSlide1 backgroundImage={backgroundImage2}>
          <h2>WORK WITH US</h2>
          <p>Help us reach women and children across Ethiopia.</p>
        </SwiperSlide1>
      </SwiperSlide>
    </Swiper>
  );
}

export default HomeSwiper;
