import DonateForm from "../components/DonateForm";
import DonationInfo from "../components/DonationInfo";
import HeadingText from "../components/HeadingText";
import { DonateContainer } from "./DonateStyle";
import demoImage from "../assets/images/kid.jpg";
import PageHeader from "../components/PageHeader";
import { useLocation } from "react-router-dom";

function Donate() {
  const location = useLocation();
  
  return (
    <DonateContainer>
      <PageHeader
        pageTitle={"Donate"}
        path={"HOME / DONATE"}
        backgroundImage={demoImage}
      />
      <HeadingText>
        {location.state?.causeTitle && (
          <h3>
            Thank you for deciding to make a donation to the Cause: '
            <span>{location.state.causeTitle}'</span><br/><span> Here is how to donate.</span>
          </h3>
        )}
        {!location.state?.causeTitle && (
          <h3>
            Thank you for deciding to make a donation.
            <span> Here is how to donate.</span>
          </h3>
        )}
      </HeadingText>
      <p>
        Our charity organization works tirelessly to provide women and children
        in rural areas of Ethiopia with the opportunity to pursue education,
        access maternal health facilities and provide them with the necessary
        equipment and support. To achieve this goal, donations from generous
        individuals are needed for procurement of supplies and dispatching of
        necessary experts.
      </p>
      <p>
        Learn more about out <span>Vision.</span>
      </p>
      <DonateForm />
      <DonationInfo />
    </DonateContainer>
  );
}

export default Donate;
