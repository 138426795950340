import React from "react";
import { Circles } from "react-loader-spinner";
import styled from "styled-components";

const SpinnerContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(35, 30, 32, 0.45);
    display: grid;
    place-items: center;
    >div{
        padding: 4rem;
        background-color: var(--primary-color);
        color: white;
        display: grid;
        place-items: center;
    }
`

function LoadingSpinner() {
  return (
    <SpinnerContainer>
      <div>
      <Circles
        height="80"
        width="80"
        color='var(--secondary-color)'
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <h4>Sending now. Please wait</h4>
      </div>
    </SpinnerContainer>
  );
}

export default LoadingSpinner;
