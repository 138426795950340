import { VisionBannerContainer } from "./VisionBannerStyle"

function VisionBanner() {
  return (
    <VisionBannerContainer>
        <div>
            <h2>Our Vision</h2>
            <h3>Create a world where no child goes to school hungry</h3>
        </div>
    </VisionBannerContainer>
  )
}

export default VisionBanner