import { useParams } from "react-router-dom";
import SideBar from "../components/SideBar";
import NewsCardAlt from "../components/SmallComponents/NewsCardAlt";
import { NewsDetailsContainer } from "./NewsDetailStyle";
import { DataContext } from "../contexts/DataContext";
import { useContext } from "react";

function NewsDetail() {
  const params = useParams();
  const { news } = useContext(DataContext);
  const newsItem = news.find((aNews) => String(aNews.id) === params.newsId);
  return (
    <NewsDetailsContainer>
      <div>
        <NewsCardAlt news={newsItem} />
        <p>{newsItem?.brief}</p>
        {newsItem?.detail.split("///p").map((par, index) => {
          return (
            <p key={index} className="par">
              {par}
            </p>
          );
        })}
      </div>
      <div>
        <div>
          <SideBar />
        </div>
      </div>
    </NewsDetailsContainer>
  );
}

export default NewsDetail;
