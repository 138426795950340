import { HeadingTextContainer } from "./HeadingTextStyle"


//use h3 text and use span for bolded and underlined section 
function HeadingText(props) {
  return (
    <HeadingTextContainer>
        {props.children}
    </HeadingTextContainer>
  )
}

export default HeadingText