import styled from "styled-components";
import backgroundImage from '../../assets/images/twogirls.jpg'
export const SwiperSlide1Container = styled.div`
    height: 30vh;
    width: 100vw;
    background-image: linear-gradient(rgba(90, 90, 90, 0.48),rgba(90, 90, 90, 0.48)),url(${({$backgroundimage})=>$backgroundimage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 2rem;
    >h2{
        color:white;
        font-size: clamp(1.5rem,4vw,2rem);
        font-weight: 300;
    }
    >p{
        color: white;
        font-size: clamp(1rem,3vw,1.5rem);
        font-weight: 300;
    }
    >button{
        font-size: clamp(0.9rem,3vw,1.1rem);
        background-color: rgba(0,0,0,0.5);
        padding:0 3vw;
    }

`