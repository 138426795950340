import HeadingText from "./HeadingText";
import {DonationInfoContainer} from './DonationInfoStyle'

function DonationInfo() { 
  return (
    <DonationInfoContainer>
      <HeadingText>
        <h3>
          Finished with the form? <span>Here is how to donate.</span>
        </h3>
      </HeadingText>
      <p>
        Please make transfers to the following bank accounts and then email us
        the receipt. Our volunteers will shortly get in touch with you to
        provide certification for your generous donation.{" "}
      </p>
      <h3>Bank Accounts</h3>
      <div className="account">
        <p>Commercial Bank of Ethiopia</p>
        <p>1000123456678</p>
        <p>Kameti charity orgamization</p>
      </div>
      <div className="account">
        <p>Commercial Bank of Ethiopia</p>
        <p>1000123456678</p>
        <p>Kameti charity orgamization</p>
      </div>
      <div className="account">
        <p>Commercial Bank of Ethiopia</p>
        <p>1000123456678</p>
        <p>Kameti charity orgamization</p>
      </div>
      <h3>Thank you for your generousity!</h3>
    </DonationInfoContainer>
  );
}

export default DonationInfo;
