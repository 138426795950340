import styled from "styled-components";

export const NewsContainer = styled.div`
    .news-grid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        display: grid;
        grid-template-columns: repeat(4,1fr);
        place-items:center;
        width: 99%;
        padding: 0;
        gap: 1rem;
        
        @media screen and (max-width:1500px){
            grid-template-columns: repeat(3,1fr);
        }
        @media screen and (max-width:1150px){
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width:768px){
            grid-template-columns: repeat(1,1fr);
        }
    }
`