import styled from "styled-components";
import PrimaryButton from "../PrimaryButton";

const PopUpContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(35, 30, 32, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  > div {
    width: 40vw;
    height: 30vh;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    position: relative;
    border: 2px var(--secondary-ascent-color) solid;
    color:white;
    @media screen and (max-width:768px){
        width: 100vw;
        height: 35vh;
    }
    >p{
        font-weight: 300;
        color: var(--tertiary-color);
        font-size: 1rem;
    }
    h3{
        font-weight: 500;
        font-size: 1.3rem;
    }

    >button{
        position    :absolute;
        bottom: 2rem;
        right: 2rem;
        padding: 0.5rem 2rem;
    }

  }
`;

function PopUp(props) {
    /*use h3 as title and p as message */
  return (
    <PopUpContainer>
      <div>
        {props.children}
        <p>Thank you!</p>
        <PrimaryButton onClick={()=>props.setShowPopUp(false)}>Ok</PrimaryButton>
      </div>
    </PopUpContainer>
  );
}

export default PopUp;
