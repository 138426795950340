import styled from "styled-components";

export const HeadingTextContainer = styled.div`

  text-align: center;
  padding: 0 0.5rem;
  > h3 {
    //font-size: clamp(1.2rem, 3vw, 2rem);
    font-weight: 300;
    font-size: 2.8vh;
  }
  span {
    font-weight: 500;
    border-bottom: 1px var(--secondary-color) solid;
  }
`;
