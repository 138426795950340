import styled from "styled-components";

export const DonateContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    
    >p,>form{
        box-sizing: border-box;
        padding: 1rem;
    }
    >p:first-of-type{
        text-align: center;
        line-height: 1.5;
        color: var(--text-alt-color);
        box-sizing: border-box;
        
    }
    >p:nth-of-type(2){
        >span{
            color: var(--secondary-color);
            cursor: pointer;
            transition: border 200ms ease-in-out;
            &:hover{
                border-bottom: 1px var(--secondary-ascent-color) solid;

            }
        }
    }
`