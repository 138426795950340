import styled from "styled-components";

export const SocialsContainer  = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
   
    a>img{
        height: 2rem;
        margin-right: 1rem;
        cursor: pointer;
        transition: filter 200ms ease-in-out, scale 200ms ease-in-out;
        &:hover{
            scale: 1.2;
            filter: brightness(50%);
        }
    }

`