import styled from "styled-components";
import { url_prefix } from "../../Constants";
import { convertDate } from "../../Utils";

const NewsCardAltContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  border-bottom: 1px var(--secondary-color) solid;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  >h2{
    font-weight: 500;
  }
  > img {
    width:100%;
  }
  > div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    > p {
      color: var(--text-alt-color);
      text-align: center;
      font-size: 1rem;
    }

  }
`;

function NewsCardAlt({news}) {
  return (
    <NewsCardAltContainer>
      <img src={url_prefix+news?.image} alt="" />
      <h2>{news?.title}</h2>
      <div>
        <p>{convertDate(news?.dateCreated)}</p>
        <p>{news?.address}</p>
      </div>
    </NewsCardAltContainer>
  );
}

export default NewsCardAlt;
