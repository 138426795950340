import axios from "axios";
import { FooterContainer } from "./FooterStyle";
import PrimaryButton from "./PrimaryButton";
import ContactInfoList from "./SmallComponents/ContactInfoList";
import PopUp from "./SmallComponents/PopUp";
import Socials from "./Socials";
import { useState } from "react";
import { url_mailinglist_post } from "../Constants";
import LoadingSpinner from "./LoadingSpinner";
function Footer() {
  const [ShowPopUp, setShowPopUp] = useState(false);
  const [mailingEmail,setMailingEmail] = useState('')
  const [error,setError] = useState(true)
  const [showSpinner,setShowSpinner] = useState(false)
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const handleChange = (e) =>{
    setMailingEmail(e.target.value)
  }
  const handleClick = (e) => {

    e.preventDefault();
    setShowSpinner(true)
    axios
    .post(url_mailinglist_post,{email:mailingEmail},{cancelToken:source.cancelToken})
    .then((response=>{
      setError(false)
      setShowPopUp(true)
      setShowSpinner(false)
      setMailingEmail('')
    }))
    .catch((error)=>{
      source.cancel()
      setError(true);
      setShowSpinner(false);
      setShowPopUp(true);
    })
    setTimeout(() => {
  
      if(error){
        source.cancel('Operation canceled by the user.');
        setShowSpinner(false);
        setShowPopUp(true);
      }
  }, 15000)
  };
  return (
    <FooterContainer>
      <div>
      <h3>KAMETI</h3>
        <p>
          Kameti is a charity organization focused on the assistance of women
          and children. We are established in 2023 in Addis Abeba, Ethiopia to
          provide support in education, health and overall wellbeing of women
          and children in the country. Our vision is to support communities and
          schools by providing necessary equipment, networking and assistance to
          enable children and women to stand form within the society.
        </p>
        <ContactInfoList/>
      </div>
      <div>
        <h3>Mailing List Signup</h3>
        <form onSubmit={handleClick}>
            <input type="mail" name="email" required value={mailingEmail} placeholder="Email" onChange={handleChange}/>
            {ShowPopUp && (
        <PopUp setShowPopUp={setShowPopUp}>
        {!error && <h3>Added to mailing list!</h3>}
        {error && <h3>Failed to add to mailing list!</h3>}
        {!error && (
          <p>
           We will now send you updates through your email.
          </p>
        )}
        {error && (
          <p>
            You have not been added to mailing list. Please try again. We apologize
            for the inconvenience.
          </p>
        )}
      </PopUp>
      )}
            <PrimaryButton type = 'submit'>Submit</PrimaryButton>
        </form>
        <h4>Follow Us</h4>
        <Socials/>
        <p>Copyright 2023, All rights reserved by KAMETI charity organization</p>
      </div>
      {showSpinner && <LoadingSpinner/>}
    </FooterContainer>
  );
}

export default Footer;
