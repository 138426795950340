import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: 'Roboto',Open-Sans, Helvetica, Sans-Serif;
    }
    :root{
        --primary-color : #231E20;
        --secondary-color: #FFC000;
        --secondary-ascent-color:#FFA000;
        --tertiary-color:#F4F4F4;
        --text-alt-color:#545454;
        --tertiary-alt-color:#E4E4E4;
    }
`

export default GlobalStyles