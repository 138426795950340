import PrimaryButton from './PrimaryButton'
import { NewsCardContainer } from './NewsCardStyle'
import { useNavigate } from 'react-router-dom'
import { url_prefix } from '../Constants'

function NewsCard({news}) {
  const navigate = useNavigate()
  const handleClick =(link,id)=>{
    navigate(link+id)
  }
  return (
    <NewsCardContainer onClick={()=>handleClick('/news/',news.id)}>
        <img src={url_prefix+news?.image} alt="" />
        <h3>{news?.title}</h3>
        <p>{news?.brief}</p>
        <PrimaryButton>
            READ MORE
        </PrimaryButton>
    </NewsCardContainer>
  )
}

export default NewsCard