import axios from "axios";
import { useEffect, useState } from "react";

const useFetch = (url)=>{
    const [data,setData] = useState([])
    const [loading,setLoading] = useState(false)
    const [error,setError] = useState(false)

    useEffect(()=>{
        const fetchData = async ()=>{
            setLoading(true)
            try {
                const {data:response} = await axios.get(url)
                setData(response)
                

            }catch(error){
                setError(true)
            }
            setLoading(false)
        }
        fetchData();
    },[url])
    return {data,loading,error}
}

export default useFetch;