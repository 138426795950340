import styled from "styled-components";

export const HowToDonateSubItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    >h2{
        background-color: var(--secondary-color);
        width: 5rem;
        height: 5rem;
        text-align: center;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    >h3{
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
    }
    >p{
        color: var(--text-alt-color);
        text-align: center;
    }
`
export const HowToDonateContainer = styled.div`
    background-color: var(--tertiary-color);
    display: flex;
    width: 100%;
    >div{
        flex: 1;
    }
    @media screen and (max-width:768px){
        flex-direction: column;
    }
`