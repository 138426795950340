import ContactUsForm from "../components/ContactUsForm";
import GetInTouch from "../components/GetInTouch";
import HeadingText from "../components/HeadingText";
import { ContactUsContainer } from "./ContactUsStyle";
import demoImage from "../assets/images/kid.jpg";
import PageHeader from "../components/PageHeader";
import { useLocation } from "react-router-dom";

function ContactUs() {
  const location = useLocation();
  return (
    <ContactUsContainer>
      <PageHeader
        pageTitle={"Contact Us"}
        path={"HOME / CONTACT US"}
        backgroundImage={demoImage}
      />
      <HeadingText>
        {location.state?.type && location.state?.type === "volunteer" && (
          <h3>
            We are happy you want to <span>volunteer</span>. Leave us a message
            and we will contact you shortly.
          </h3>
        )}
        {!location.state?.type && (
          <h3>
            We are always happy to hear form you.<span> Send us a message</span>
          </h3>
        )}
      </HeadingText>
      <div>
        <ContactUsForm />
        <GetInTouch />
      
      </div>
    </ContactUsContainer>
  );
}

export default ContactUs;
