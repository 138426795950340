import { SayingsContainer } from "./SayingsStyle";
import HeadingText from "./HeadingText";
import { useEffect, useState } from "react";
import { url_saying_get } from "../Constants";
import axios from "axios";
function Sayings() {
  const [saying, setSaying] = useState({});
  useEffect(() => {
    axios
      .get(url_saying_get)
      .then((response) => {
        setSaying(response.data);
      })
      .catch((error) => {
        setSaying({
          saying:
            "The purpose of life is not to be happy. It is to be useful, to be honorable, to be compassionate, to have it make some difference that you have lived and lived well.",
          author: "Ralph Waldo Emerson",
        });
      });
  }, []);
  return (
    <SayingsContainer>
      <HeadingText>
        <h3>
          Famous quotes from donors. <span>Read below.</span>{" "}
        </h3>
      </HeadingText>
      <p>
        <span>"</span>
        {saying.saying}
        <span>"</span>
      </p>
      <h3>{saying.author}</h3>
    </SayingsContainer>
  );
}

export default Sayings;
