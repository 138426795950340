import { useContext } from "react";
import CausesCard from "../components/CausesCard";
import HeadingText from "../components/HeadingText";
import HowToHelp from "../components/HowToHelp";
import NewsCard from "../components/NewsCard";
import Sayings from "../components/Sayings";
import HomeSwiper from "../components/Swiper/HomeSwiper";
import { HomeContainer } from "./HomeStyle";
import { DataContext } from "../contexts/DataContext";

function Home() {
  const { causes, news } = useContext(DataContext);
  return (
    <HomeContainer>
      <HomeSwiper />
      <HeadingText>
        <h3>
          Help various women and children in Ethiopia in their education.{" "}
          <span>Go through our causes.</span>{" "}
        </h3>
      </HeadingText>
      <div className="home-grid">
        {causes?.map((cause, index) => {
          if (index  >causes.length-4) {
            return <CausesCard key={index} cause={cause} />;
          }
          return;
        })}
      </div>
      <p>
        See all <span>CAUSES</span>
      </p>
      <HowToHelp />
      <HeadingText>
        <h3>
          Stay informed. <span>Latest news.</span>{" "}
        </h3>
      </HeadingText>
      <div className="home-grid">
        {news?.map((newsItem, index) => {
          if (index >news.length-4) {
            return <NewsCard key={index} news={newsItem} />;
          }
          return;
        })}
      </div>
      <p>
        See all <span>NEWS</span>
      </p>
      <Sayings />
    </HomeContainer>
  );
}

export default Home;
