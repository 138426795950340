import styled from "styled-components";
import bannerImage from "../assets/images/nana.jpg"

export const VisionBannerContainer = styled.div`
    margin: 0;
    padding: 1rem 0;
    background-image: url(${bannerImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position:40% ;
    width: 100%;
    >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(13, 13, 13, 0.459);
        >h2{
            color: var(--secondary-color);
            //font-size: clamp(1.8rem,2vw,2.5rem);
            font-size: 5.9vw;
            font-weight: 500;
            }
            >h3{
            text-align: center;
            color: white!important;;
            font-size: clamp(1.3rem,1.5vw,1.8rem);
            font-weight: 400;
            letter-spacing: 0.1cap;
            border-bottom: 2px var(--secondary-ascent-color) solid;
            padding-bottom: 0.7rem;
        }
    }
`