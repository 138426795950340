import styled from "styled-components";

export const DonateFormContainer = styled.form`
  width: 100%;
  padding: 1rem;
  input,
  textarea {
    background-color: var(--tertiary-color);
    height: 2.5rem;
    border: 1px var(--tertiary-alt-color) solid;
    font-size: 1rem;
    &:focus {
      outline: none;
      border: 1px var(--secondary-color) solid;
    }
  }
  label {
    margin: 0.8rem 0;
    color: var(--text-alt-color);
    >span{
      color: var(--secondary-ascent-color);
    }
  }
  textarea{
        height: 10rem;
    } 
    >button{
        color: black;
        padding: 0.5rem 2.5rem;
    }
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    > * {
      width: 100%;
    }
  }
  > div:nth-child(2),
  > div:nth-child(3),
  > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    > div {
      width: 100%;
    }
    > div:first-of-type {
      margin-right: 2rem;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      > div:first-of-type {
        margin-right: 0;
      }
    }
  }
`;
