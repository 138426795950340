import styled from "styled-components";

export const ContactUsContainer = styled.div`
    >div:last-of-type{
        display: grid;
        grid-template-columns: 3fr 1fr;
        @media screen and (max-width:768px) {
            grid-template-columns: 1fr;            
        }
    }
`