import React, { useContext } from 'react'
import SideBarCard from './SmallComponents/SideBarCard'
import styled from 'styled-components'
import { DataContext } from '../contexts/DataContext'

const SideBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30vw;
    max-width: 40rem;
    >div>h3{
        font-size: 1.2rem;
        font-weight: 500;
        padding-bottom: 1rem;
        border-bottom: 1px var(--secondary-ascent-color) solid;
    }
    >div>div{
        margin-bottom: 1rem;
    }
    @media screen and (max-width:768px) {
      padding-left: 1rem;
      width: 100%;
      box-sizing: border-box;
    }
 
`

function SideBar() {
  const {causes,news} = useContext(DataContext)
  return (
    <SideBarContainer>
      <div>
      <h3>Active Causes</h3>
        {(causes.slice(0,3)).map((item,index)=>{
          return <SideBarCard key={index} item={item} link={'/causes'}/>
        })}
      </div>
        <div>
        <h3>Recent New</h3>
        {(news.slice(0,3)).map((item,index)=>{
          return <SideBarCard key={index} item={item} link={'/news'}/>
        })}
        </div>
    </SideBarContainer>
  )
}

export default SideBar