import styled from "styled-components";
import { SocialsContainer } from "./SocialsStyle";

export const GetInTouchContainer = styled.div`
  background-color: var(--primary-color);
  color: rgba(255, 255, 255, 0.6);
  padding: 1rem;
  p {
    font-weight: 300;
    font-size: 0.9rem;
  }
  >div>div:first-of-type>p:first-of-type{
   @media screen and (min-width:768px){
    height: 2.8rem;
   }
  }
  >p{
    text-align: center;
}
>p,>div,>${SocialsContainer}{
margin-top: 2rem;
}
> h3 {
    text-align: center;
    font-weight: 300;
    font-size: 1.5rem;
  }
  > div:first-child,
  > div:nth-child(2) {
    display: flex;
    > div {
      margin-right: 3rem;
      display: flex;
      flex-direction: column;
      > p {
      }
    }
  }
`;
