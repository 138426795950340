import HeadingText from "./HeadingText";
import { HowToHelpContainer } from "./HowToHelpStyle";

function HowToHelp() {
  return (
    <HowToHelpContainer>
      <HeadingText>
        <h3>
          How can you help?<span> See Below.</span>
        </h3>
      </HeadingText>
      <div>
        <div>
          <div>
            <h3>Make a donation</h3>
            <p>
              Help children and women in ethiopia through their education and
              heath by making a donation. Your money goes a long way in
              transforming the lives of these individuals
            </p>
          </div>
          <div>
            <h3>Become a Volunteer</h3>
            <p>
              By volunteering at our charity organization, you can help us reach
              different areas. Volunteering helps our charity organization to
              expand its operations beyond the current reach.
            </p>
          </div>
          <div>
            <h3>Put the word out</h3>
            <p>
              Informing other individuals, organizations and groups about our
              causes and charity work helps us reach more individuals in need of
              assistance.
            </p>
          </div>
        </div>
        <div>
          <h3>Watch our video</h3>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/bfAzi6D5FpM?si=egH8EhjnSQMwPCcA"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </HowToHelpContainer>
  );
}

export default HowToHelp;
