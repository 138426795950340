import styled from "styled-components";
import backgroundImage from '../assets/images/kid.jpg'

export const SayingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: #EAEAEA;
    width: 100%;
    box-sizing: border-box;
    @media screen and (min-width:1024px){
            padding: 0 10vw;
        }
    
    >p{
        font-size: clamp(1rem,3vw,1.5rem);
        text-align: center;
        font-weight: 300;
        line-height: 3cap;
        >span{
            color: var(--secondary-color);
            font-size: clamp(1.2rem,3vw,1.7rem);
        }
      
    }
    >h3{
        font-size: 1.1rem;
        font-weight: 500;
    }

    background-image: linear-gradient(rgba(35,30,32,0.88),rgba(35,30,32,0.88)), url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
`