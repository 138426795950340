import styled from "styled-components";

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .home-grid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        place-items:center;
        width: 99%;
        padding: 0;
        margin: 0;
        gap: 1rem;
        @media screen and (max-width:1500px){
            grid-template-columns: repeat(3,1fr);
           
        }
        @media screen and (min-width:1024px){
            grid-template-columns: repeat(3,1fr);
            margin: 0 10vw;
            width: auto;
        }
        @media screen and (max-width:1150px){
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width:768px){
            grid-template-columns: repeat(1,1fr);
        }
    }
    >p>span{
        color: var(--secondary-color);
        cursor: pointer;
        transition: border 200ms ease-in-out;
        &:hover{
            border-bottom: 1px var(--secondary-ascent-color) solid;
    }
}
`