import { useState } from "react";
import { ContactUsFormContainer } from "./ContactUsFormStyle";
import PrimaryButton from "./PrimaryButton";
import PopUp from "./SmallComponents/PopUp";
import axios from "axios";
import { url_contact_post } from "../Constants";
import LoadingSpinner from "./LoadingSpinner";
function ContactUsForm() {
  const [ShowPopUp, setShowPopUp] = useState(false);
  const [ShowSpinner, setShowSpinner] = useState(false);
  const [form, setForm] = useState({
    name:'',email:'',address:'',message:''
  })
  const [error,setError] = useState(true)
  
  const handleChange = (e)=>{
    setForm({...form,[e.target.name]:e.target.value})
  }
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const handleClick = (e) => {
    e.preventDefault();
    setShowSpinner(true)
    axios.post(url_contact_post,form,{cancelToken:source.token})
    .then(response=>{
      setError(false)
      setShowPopUp(true)
      setShowSpinner(false)
      setForm({
        name:'',email:'',address:'',message:''
      })
    })
    .catch(error=>{
      setError(true);
      source.cancel()
      if (error.code === "ECONNABORTED") {
        setShowSpinner(false);
        setShowPopUp(true);
      }
    })
    setTimeout(() => {
      if(error){
        source.cancel('Operation canceled by the user.');
        setShowSpinner(false);
        setShowPopUp(true);
      }
  }, 15000)
    
  };
  return (
    <ContactUsFormContainer onSubmit={handleClick}>
      <div>
        <div className="inner">
          <label htmlFor="name">Name <span>*</span></label>
          <input type="text" name="name" required value={form.name} onChange={handleChange}/>
        </div>
        <div className="inner">
          <label htmlFor="email">Email <span>*</span></label>
          <input type="email" name="email" required value={form.email} onChange={handleChange}/>
        </div>
      </div>
      <div className="inner">
        <label htmlFor="address">Address <span>*</span></label>
        <input type="text" name="address" required value={form.address} onChange={handleChange}/>
      </div>
      <div className="inner">
        <label htmlFor="message">Message <span>*</span></label>
        <textarea name="message" required value={form.message} onChange={handleChange}/>
      </div>
      <PrimaryButton>SUBMIT</PrimaryButton>
      {ShowPopUp && (
        <PopUp setShowPopUp = {setShowPopUp}>
           {!error && <h3>Message sent successfully!</h3>}
          {error && <h3>Message failed to send!</h3>}
          {!error && (
            <p>
              we have received your message. We will get in touch with you shortly.
            </p>
          )}
          {error && (
            <p>
              Message failed to send. Please try again. Wea appologize for the inconvenience.
            </p>
          )}
        </PopUp>
      )}
      {ShowSpinner && <LoadingSpinner/>}
    </ContactUsFormContainer>
  );
}

export default ContactUsForm;
