import { useNavigate } from "react-router-dom"
import { NavDropDownContainer } from "./NavDropDownStyle"

function NavDropDown({link,data}) {
  const navigate = useNavigate()
  const handleClick =(id) =>{
    navigate(link+'/'+id)
  }
  return (
    <NavDropDownContainer>
       {data && data?.map((dataItem,index)=>{
          return <li key={index} onClick={()=>handleClick(dataItem.id)}>{dataItem.title}</li>
        })} 
    </NavDropDownContainer>
  )
}

export default NavDropDown