import React from "react";
import PrimaryButton from "./PrimaryButton";
import { CausesCardContainer } from "./CausesCardStyle";
import { useNavigate } from "react-router-dom";
import { url_prefix } from "../Constants";
import { calcCauseDonationPercentage } from "../Utils";
import pillarImage from "../assets/icons/pillar.png"

function CausesCard({cause}) {
  const navigate = useNavigate()
  const handleClick =(link,id)=>{
    navigate(link+id)
  }
  return (
    <CausesCardContainer $percentage={calcCauseDonationPercentage(cause?.totalDonated,cause?.totalRequired)}onClick={()=>handleClick('/causes/',cause.id)}>
      <h5>{cause?.title}</h5>
      <div>
        <img src={url_prefix+cause?.image} alt="myimage" />
        <div></div>
        <div>
          <p>{calcCauseDonationPercentage(cause?.totalDonated,cause?.totalRequired)}</p>
        </div>
      </div>
      <h6>
        Donation:<span>{Math.floor(cause?.totalDonated).toLocaleString()}</span> / <span>{Math.floor(cause?.totalRequired).toLocaleString()}</span>
      </h6>
      <p className="pillarName">
        <img src={pillarImage} alt="pillar: " />
        {cause?.pillar?.name}
      </p>
      <p >
        {cause?.brief}
      </p>
      <PrimaryButton>DONATE NOW</PrimaryButton>
    </CausesCardContainer>
  );
}

export default CausesCard;
