import styled from "styled-components";

export const PrimaryButtonContainer = styled.button`
    background: none;
    border: 2px var(--secondary-ascent-color) solid;
    color: white;
    font-family: 'Roboto';
    font-size: 1rem;
    font-weight:500;
    padding: 0rem 1rem;
    cursor: pointer;
    
    transition: background-color 100ms ease-in-out;
    &:hover{
        background-color: var(--secondary-color);
    }
`