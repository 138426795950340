import { PageHeaderContainer } from "./PageHeaderStyle";


function PageHeader(props) {
  return <PageHeaderContainer $backgroundimage={props.backgroundImage}>
    <h2>{props.pageTitle}</h2>
    <p>{props.path}</p>
  </PageHeaderContainer>;
}

export default PageHeader;
