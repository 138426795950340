import { useParams } from "react-router-dom";
import CausesCardAlt from "../components/CausesCardAlt";
import HowToDonate from "../components/HowToDonate";
import SideBar from "../components/SideBar";
import { CausesDetailContainer, CausesDetailInner } from "./CausesDetailStyle";
import { useContext } from "react";
import { DataContext } from "../contexts/DataContext";

function CausesDetail() {
    const params = useParams()
    const {causes} = useContext(DataContext)
    const cause = causes.find((cause)=>String(cause.id) === String(params.causeId))
  return (
    <CausesDetailContainer>
      <CausesDetailInner>
        <div>
          <CausesCardAlt cause={cause}/>
          <p className="par">
           {cause?.brief}
          </p>
          {cause?.detail.split("///p").map((par, index)=>{
              return <p key={index} className="par">{par}</p>
            })}
        </div>
        <div>
          <div>
            <SideBar/>
          </div>
        </div>
      </CausesDetailInner>
      <HowToDonate/>
    </CausesDetailContainer>
  );
}

export default CausesDetail;
