import styled from "styled-components"
import locationIcon from '../../assets/icons/location.png'
import PhoneIcon from '../../assets/icons/phone.png'
import mailIcon from '../../assets/icons/mail.png'

const ContactInfoListContainer = styled.ul`
    list-style: none;
    line-height: 3cap;
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
    padding:0;

    li{
        padding-left: 1rem;
        color: rgba(255,255,255,0.71);
    }
    >li:nth-child(1)::before{
        content: '   ';
        padding-left: 2rem;
        background-image: url(${locationIcon});
        background-size: contain;
        background-repeat: no-repeat;
    }
    >li:nth-child(2)::before{
        content: '   ';
        padding-left: 2rem;
        background-image: url(${PhoneIcon});
        background-size: contain;
        background-repeat: no-repeat;
    }
    >li:nth-child(3)::before{
        content: '   ';
        padding-left: 2rem;
        background-image: url(${mailIcon});
        background-size: contain;
        background-repeat: no-repeat;
    }
`


function ContactInfoList() {
  return (
   <ContactInfoListContainer>
        <li>Bole, Getu commercial, 5th flood, Addis Abeba, Ethiopia</li>
        <li>+251-911234567</li>
        <li>Contact@kameti.org</li>
   </ContactInfoListContainer>
  )
}

export default ContactInfoList