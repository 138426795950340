import styled from "styled-components";
import { NavDropDownContainer } from "./NavDropDownStyle";

export const NavbarContainer = styled.div`
    background-color: var(--secondary-color);
    position: sticky;
    top: 0;
    z-index: 10;
    &.open{
        display: flex;
    }
    

    @media screen and (max-width:768px){
        position: fixed;
        right: 0;
        top:3rem;
        width: 60vw;
        height: 100vh;
        display: none;
        overflow-y: scroll;
    }
    @media screen and (max-width:480px){
        width: 80vw;
    }

    >ul{
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding:0 1rem;

        @media screen and (max-width:768px){
        flex-direction: column;
        align-items: flex-start;
        justify-content:flex-start;
        >li{
            margin-top: 1rem;
        }
    }
        >li{
            padding: 0.5rem;
            cursor: pointer;
            position: relative;
            &:hover{
                font-weight: 500;
                >${NavDropDownContainer}{
                    display: flex;
                }
            }
            >a{
                color: inherit;
                text-decoration: none;
            }
        }
    }
    
`