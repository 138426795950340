import styled from "styled-components";

export const HowToHelpContainer = styled.div`
  background-color: var(--tertiary-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
 @media screen and (min-width:1024px){
            padding: 0 10vw;
        }
  >div:nth-of-type(2){
    display: grid;
    grid-template-columns: 1fr 1fr;
   
    @media screen and (max-width:768px) {
    grid-template-columns: 1fr  ;
    
    iframe{
        width:352px ;
    }
  }

    > div:first-of-type {
    h3 {
      color: var(--secondary-ascent-color);
      font-weight: 500;
      font-size: 1.2rem;
    }
    p {
      color: var(--text-alt-color);
      font-weight: 300;
      font-size: 0.9rem;
    }
  }
  >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >h3{
        color: var(--secondary-ascent-color);
    }
    
  }
  }
`;
