import styled from "styled-components";

export const CausesDetailInner = styled.div`
    display: grid;
    grid-template-columns: 5fr 3fr;
    justify-content: center;
    .par{
        padding: 0!important;
    }

    @media screen and (max-width:768px){
        grid-template-columns: 1fr;
    }
    .par{
        color:  #373D3F;
        padding: 0 6rem;
        margin-bottom: 1rem;
        text-indent: 4cap;
        
    }
    @media screen and (max-width:768px){
        .par{
            padding: 0 3rem;
            text-indent: 2cap;
        }
    } 
    @media screen and (max-width:480px){
        .par{
            padding: 0 1rem;
            text-indent: 1cap;
        }
    } 
    >div:first-of-type{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem;
        @media screen and (max-width:768px){
            padding: 1rem;
        }
        >p{
            text-align: start;
            font-size: 1rem;
            line-height: 2cap;
            letter-spacing: .1ch;
        }
        >p:first-of-type{
            font-weight: 500;
        }
    }
    >div:last-of-type{
        >div{
            position: sticky;
            top: 2rem;
            >*{
                width: 100%;
            }
        }
    }
    
`
export const CausesDetailContainer = styled.div`

`
    
