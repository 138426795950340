import { GetInTouchContainer } from "./GetInTouchStyle";
import Socials from "./Socials";

function GetInTouch() {
  return (
    <GetInTouchContainer>
      <h3>Get in touch</h3>
      <div>
        <div>
          <p>Address</p>
          <p>Email</p>
          <p>Tel</p>
        </div>
        <div>
          <p>Getu commercial, 5th floor , 508 Addis Abeba, Ethiopia</p>
          <p>contact@kameti.org</p>
          <p>+251-9123456</p>
          <p>+251-9123456</p>
        </div>
      </div>
      <Socials/>
      <p>Feel free to contact us through any of the above platforms. We are always a call or an email away ready to answer any questions you may have. </p>
    </GetInTouchContainer>
  );
}

export default GetInTouch;
