import CausesCard from "../components/CausesCard"
import HeadingText from "../components/HeadingText"
import { CausesContainer } from "./CausesStyle"
import PageHeader from "../components/PageHeader"
import demoImage from '../assets/images/kid.jpg'
import { useContext } from "react"
import { DataContext } from "../contexts/DataContext"

function Causes() {
  const {causes} = useContext(DataContext)
  return (
    <CausesContainer>
        <PageHeader pageTitle={"Causes"} path={"HOME / CAUSES"} backgroundImage={demoImage}/>
        <HeadingText>
            <h3>You can help many women and children by donating a little.<span>Our Causes</span></h3>
        </HeadingText>
        <div className="causes-grid">
            {causes?.map((cause,index)=>{
              return <CausesCard key={index} cause={cause}/>
            })}
        </div>
    </CausesContainer>
  )
}

export default Causes