import { PrimaryButtonContainer } from "./PrimaryButtonStyle"

function PrimaryButton(props) {
  return (
    <PrimaryButtonContainer onClick={props.onClick}>
        {props.children}
    </PrimaryButtonContainer>
  )
}

export default PrimaryButton