import { CausesCardAltContainer } from "./CausesCardAltStyle";
import PrimaryButton from "./PrimaryButton";
import { useNavigate } from "react-router-dom";
import { url_prefix } from "../Constants";
import { calcCauseDonationPercentage, convertDate } from "../Utils";
function CausesCardAlt({cause}) {
  const navigate = useNavigate();
  const handleClick = (link, causeId, causeTitle) => {
    navigate(link, { state: { causeID: causeId, causeTitle: causeTitle } });
  };
  return (
    <CausesCardAltContainer $percentage={calcCauseDonationPercentage(cause?.totalDonated,cause?.totalRequired)}>
      <h5>{cause?.title}</h5>
      <div>
        <p>{convertDate(cause?.dateCreated)}</p>
        <p> {cause?.address}</p>
      </div>
      <div>
        <img src={url_prefix+cause?.image} alt="myimage" />
        <div></div>
        <div>
          <p>{calcCauseDonationPercentage(cause?.totalDonated,cause?.totalRequired)}%</p>
        </div>
      </div>
      <div>
        <h6>
          Donation:<span>{Math.floor(cause?.totalDonated)}</span> / <span>{Math.floor(cause?.totalRequired)}</span>
        </h6>

        <PrimaryButton
          onClick={() => handleClick("/donate/", 1, "this is the title")}
        >
          DONATE NOW
        </PrimaryButton>
      </div>
    </CausesCardAltContainer>
  );
}

export default CausesCardAlt;
