import React from "react";
import FbIcon from "../assets/icons/fb.svg";
import TwitterIcon from "../assets/icons/twitter.svg";
import InstagramIcon from "../assets/icons/instagram.svg";
import TelegramIcon from "../assets/icons/telegram.svg";
import { SocialsContainer } from "./SocialsStyle";

function Socials() {
  return (
    <SocialsContainer>
      <a href="http://www.facebook.com">
        <img src={FbIcon} alt="FB" />
      </a>
      <a href="http://www.x.com">
        <img src={TwitterIcon} alt="Twitter" />
      </a>
      <a href="http://www.instagram.com">
        <img src={InstagramIcon} alt="Instagram" />
      </a>
      <a href="http://www.telegram.org">
        <img src={TelegramIcon} alt="Telegram" />
      </a>
    </SocialsContainer>
  );
}

export default Socials;
