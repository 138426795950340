import styled from "styled-components";

export const CausesContainer = styled.div`

    .causes-grid{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 1rem;
        place-items:center;
        width: 99%;
        padding: 0;
       
        @media screen and (max-width:1500px){
            grid-template-columns: repeat(3,1fr);
        }
        @media screen and (max-width:1150px){
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width:768px){
            grid-template-columns: repeat(1,1fr);
        }
    }
`