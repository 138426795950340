import styled from "styled-components"
import { SocialsContainer } from "./SocialsStyle"

export const TopBarContainer =styled.div`
    display: flex;
    height: 3rem;
    background-color: var(--primary-color);
    color: white;
    justify-content: space-between;
    padding: 0 1rem;
    z-index: 20;
    @media screen and (max-width:480px){
        >div>${SocialsContainer}{
        display: none;
    }   
    }
    >div{
        display: flex;
        align-items: center;
    }
    >div:first-of-type{
        >h2{
            cursor: pointer;
            margin-left: 2rem;
        }
        >img{
            cursor: pointer;
            height: 3rem;
        }
    }
    >div:last-of-type{
        >button{
            height: 2.5rem ;
        }
    }

    @media screen and (max-width:768px){
        position:sticky;
        top:0;
        >div>button{
            display: none;
        }
    }

`