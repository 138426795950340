import styled from "styled-components";

export const CausesCardAltContainer = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    max-width: 50vw;
    
    @media screen and (max-width:768px){
        max-width: 100%;
    }
    box-sizing: border-box;
    height: min-content;
    margin-bottom: 1rem;
    cursor: pointer;
    padding: 1rem;
    border-bottom: 2px var(--secondary-color) solid;
    @media screen and (max-width:768px){
        width: 100%;
    }

    >p{
        color: var(--text-alt-color);
        text-align: center;
        font-size: 1rem;
    }
    >h5{
        color: black;
        text-align: center;
        letter-spacing: 0.1cap;
        font-size: 1.1rem;
        font-weight: 500;
    }
    >div:last-of-type{
        display: flex;
        justify-content:space-between;
        align-items: center;
        height: 3rem;
        padding:3rem 1rem;
        width: 100%;
        box-sizing: border-box;
        >h6{
            letter-spacing: 0.1cap;
            font-size: clamp(1rem,3vw,1.4rem);
            font-weight: 300;
            padding: 0;
            margin:0;
        >span{
            font-weight: 500;
        }
        >span:nth-of-type(2){
            color:var(--secondary-ascent-color);
        }
    }
    >button{
        color:black;
        width: 50%;
        padding: 0.5rem 1rem;
    }
    }
   
   >div:first-of-type{
    display: flex;
    width: 100%;
    justify-content:space-around;
    align-items: center;
    color: var(--text-alt-color);
    >p{
        font-size: 0.9rem;
    }
   }
    >div:nth-of-type(2){
        transition:scale 200ms ease-in-out;
        position: relative;
        padding: 0;
        img{
        width: 100%;
    
        }
        >div:first-of-type{
            width: 100%;
            height: 1rem;
            background-color: var(--primary-color);
            position: absolute;
            top:98%;
        }
        >div:nth-of-type(2){
            position: absolute;
            height: 1rem;
            width:  ${(props)=>(props.$percentage)}%;
            background-color: var(--secondary-color);
            top:98%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
        }
    }
        
`;
