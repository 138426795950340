import React from 'react'
import { NavbarContainer } from './NavBarStyle'
import NavDropDown from './NavDropDown'
import { useContext } from 'react'
import { HamburgerContext } from '../../contexts/HamburgerContext'
import { Link } from 'react-router-dom'
import { DataContext } from '../../contexts/DataContext'
function NavBar() {
  
  const {hamburgerIsActive,setHamburgerIsActive} = useContext(HamburgerContext)
  const {causes,news} = useContext(DataContext)
  const handleClick = ()=>{
    setHamburgerIsActive(false)
    
  }
  return (
    <NavbarContainer className={hamburgerIsActive ? 'open':''}>
        <ul>
            <li onClick={handleClick}><Link to={'/vision'}>VISION</Link></li>
            <li onClick={handleClick}><Link to={'/causes'}>CAUSES</Link><NavDropDown link={'/causes'} data={causes.slice(0,3)}/></li>
            <li onClick={handleClick}><Link to={'/news'}>NEWS</Link><NavDropDown link={'/news'} data={news.slice(0,3)}/></li>
            <li onClick={handleClick}><Link to={'/contactus'}>CONTACT US</Link></li>
            <li onClick={handleClick}><Link to={'/donate'}>DONATE</Link></li>
        </ul>
    </NavbarContainer>
  )
}

export default NavBar