import styled from "styled-components";

export const VisionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 1024px) {
    > div {
      padding: 0 10%;
      width: 80%;
    }
  }

  > div:nth-of-type(2) > p {
    font-size: clamp(1.2rem, 1.2vw, 2rem);
    font-weight: 400;
    color: var(--text-alt-color);
    line-height: 3cap;
    text-indent: 3cap;
    padding: 0 1rem;

  }
  @media screen and (max-width: 1024px) {
    > div:nth-of-type(2) {
      width: 90%;
      padding: 1rem 5%;
    }
  }
  > div > h3 {

    color: var(--secondary-ascent-color);
    font-size: clamp(1.7rem, 2vw, 2.2rem);
    font-weight: 500;
    text-align: center;
    margin-top: 2rem;
  }
  .pillars{
    margin-top: 4rem;
    
  }
  > div > div {
    display: flex;

    
    
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    > div {
        margin-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      align-items: center;

      > h5 {
        font-size: clamp(1.2rem, 1.6vw, 1.4rem);
      }
      > p {
        padding: 1rem clamp(2rem, 1.5vw, 5rem);
        font-size: clamp(1.1rem, 0.9vw, 1.2rem);
        font-weight: 400;
        color: var(--text-alt-color);
        line-height: 2.5cap;
        text-indent: 3cap;
        width: 100%;
      }
    }
  }
`;
