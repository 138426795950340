import styled from "styled-components";

export const NavDropDownContainer = styled.ul`
    background-color: var(--secondary-color);
    list-style: none;
    padding:0;
    position: absolute;
    top:2.2rem;
    left: 0;
    display: none;
    flex-direction: column;
    width: max-content;
    >li{
        padding: 0.8rem 2rem;
        cursor: pointer;
        &:hover{
            background-color: var(--secondary-ascent-color);
        }
    }
    @media screen and (max-width:768px){
        position: static;
        display: flex;
        width: 100%;
        >li{
            margin-top: 1rem;
        }
    }
`