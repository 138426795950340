import { HamburgerButtonContainer } from "./HamburgerButtonStyle"
import { useContext } from "react"
import { HamburgerContext } from "../contexts/HamburgerContext"

function HamburgerButton() {
  const {hamburgerIsActive,setHamburgerIsActive} = useContext(HamburgerContext)
  return (
    <HamburgerButtonContainer className={hamburgerIsActive ? 'open':''} onClick={()=>setHamburgerIsActive(!hamburgerIsActive)}>
        <span></span>
        <span></span>
        <span></span>
    </HamburgerButtonContainer>
  )
}

export default HamburgerButton