import logo from "../assets/icons/Kameti 5.png"
import Socials from './Socials'
import { TopBarContainer } from './TopBarStyle'
import PrimaryButton from './PrimaryButton'
import HamburgerButton from './HamburgerButton'
import { useNavigate } from "react-router-dom"

function TopBar() {

  const navigate = useNavigate();
  const handleClick = (link,type)=>{
    navigate(link, {state:{type:type}})
  }
  return (
    <TopBarContainer>
        <div>
            <img onClick={()=>handleClick('/')} src={logo} alt="" />
            <h2 onClick={()=>handleClick('/')}>KAMETI</h2>
        </div>
        <div>
            <Socials/>
            <PrimaryButton onClick={()=>handleClick('/contactus',"volunteer")}>Become a volunteer</PrimaryButton>
            <HamburgerButton/>
        </div>
    </TopBarContainer>
  )
}

export default TopBar