import styled from "styled-components";

export const CausesCardContainer = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    max-width: 22rem;
    height: min-content;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover{
        >div:first-of-type{
            scale: 1.05;
        }
    }
    >p{
        color: var(--text-alt-color);
        text-align: center;
        font-size: 1rem;
    }
    >h5{
        color: black;
        text-align: center;
        letter-spacing: 0.1cap;
        font-size: 1.1rem;
        font-weight: 500;
    }
    >h6{
        letter-spacing: 0.1cap;
        font-size: 1.1rem;
        font-weight: 300;
        padding: 0;
        margin-bottom: 0rem;
        margin-top: 2rem;
        >span{
            font-weight: 500;
        }
        >span:nth-of-type(2){
            color:var(--secondary-ascent-color);
        }
    }
    >button{
        color:black;
        width: 50%;
        padding: 0.5rem 1rem;
    }
    >div:first-of-type{
        transition:scale 200ms ease-in-out;
        position: relative;
        background-color: red;
        padding: 0;
        img{
        width: 22rem;
        }
        >div:first-of-type{
            width: 100%;
            height: 1rem;
            background-color: var(--primary-color);
            position: absolute;
            top:98%;
        }
        >div:nth-of-type(2){
            position: absolute;
            height: 1rem;
            width: ${(props)=>(props.$percentage)}%;
            background-color: var(--secondary-color);
            top:98%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
        }
        
        
    }
    .pillarName{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        >img{
            padding-right: 0.5rem;
            height: 24px;
        }
    }
    
    

`