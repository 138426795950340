import { DonateFormContainer } from "./DonateFormStyle";
import PrimaryButton from "./PrimaryButton";
import { useState } from "react";
import PopUp from "./SmallComponents/PopUp";
import axios from "axios";
import { url_donate_post } from "../Constants";
import LoadingSpinner from "./LoadingSpinner";
function DonateForm() {
  const [ShowPopUp, setShowPopUp] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(true);
  const [form, setForm] = useState({
    donationAmount: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    message: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const handleClick = (e) => {
    e.preventDefault();
    setShowSpinner(true);
    axios
      .post(url_donate_post, form,{cancelToken:source.token})
      .then((response) => {
        setError(false);
        setShowPopUp(true);
        setShowSpinner(false);
        setForm({
          donationAmount: "",
          name: "",
          lastName: "",
          email: "",
          phone: "",
          address: "",
          message: "",
        });
      })
      .catch((error) => {
        source.cancel()
        setError(true);
        setShowSpinner(false);
        setShowPopUp(true);
        
      });
    
      setTimeout(() => {
  
        if(error){
          source.cancel('Operation canceled by the user.');
          setShowSpinner(false);
          setShowPopUp(true);
        }
    }, 15000)
      
  };
  
  return (
    <DonateFormContainer onSubmit={handleClick}>
      <div className="inner">
        <label htmlFor="amount">
          How much would you like to donate? <span>*</span>
        </label>
        <input
          type="text"
          name="donationAmount"
          required
          value={form.donationAmount}
          onChange={handleChange}
        />
      </div>
      <div>
        <div className="inner">
          <label htmlFor="name">
            Name/Organization <span>*</span>
          </label>
          <input
            type="text"
            name="name"
            required
            value={form.name}
            onChange={handleChange}
          />
        </div>
        <div className="inner">
          <label htmlFor="lastname">Last name</label>
          <input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <div className="inner">
          <label htmlFor="email">
            Email <span>*</span>
          </label>
          <input
            type="text"
            name="email"
            required
            value={form.email}
            onChange={handleChange}
          />
        </div>
        <div className="inner">
          <label htmlFor="phone">Phone number</label>
          <input
            type="text"
            name="phone"
            value={form.phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <div>
        <div className="inner">
          <label htmlFor="address">
            Address <span>*</span>
          </label>
          <textarea
            name="address"
            required
            value={form.address}
            onChange={handleChange}
          />
        </div>
        <div className="inner">
          <label htmlFor="message">
            Message <span>*</span>
          </label>
          <textarea
            name="message"
            required
            value={form.message}
            onChange={handleChange}
          />
        </div>
      </div>

      {ShowPopUp && (
        <PopUp setShowPopUp={setShowPopUp}>
          {!error && <h3>Donation form successfuly filled!</h3>}
          {error && <h3>Donation form failed to send!</h3>}
          {!error && (
            <p>
              Your donation form has been filled successfully. continue with the
              page to see how to transfer and finalize your donation. We will
              also get in-touch with you shortly.
            </p>
          )}
          {error && (
            <p>
              The donation form failed to send. Please try again. We apologize
              for the inconvenience.
            </p>
          )}
        </PopUp>
      )}
      {showSpinner && <LoadingSpinner />}
      <PrimaryButton>DONATE NOW</PrimaryButton>
    </DonateFormContainer>
  );
}

export default DonateForm;
