import { HowToDonateSubItemContainer,HowToDonateContainer } from "./HowToDonateStyle";

function HowToDonateSubItem(props){
    return (
        <HowToDonateSubItemContainer>
            <h2>{props.order}</h2>
            <h3>{props.title}</h3>
            <p>{props.detail}</p>
        </HowToDonateSubItemContainer>
    );
}

function HowToDonate() {
  return (
    <HowToDonateContainer>
        <HowToDonateSubItem order={'1'} title={'Select how much to donate'} detail={'Give  and get a self exposure worldwide.'}/>
        <HowToDonateSubItem order={'2'} title={'Fill out the form'} detail={'Give an opportunity , they can stand with boys and get a self exposure worldwide.'}/>
        <HowToDonateSubItem order={'3'} title={'Follow up on the work done'} detail={'Give an opportunity to girls to becf exposure worldwide.'}/>
    </HowToDonateContainer>
  )
}

export default HowToDonate