import React from "react";
import VisionBanner from "../components/VisionBanner";
import { VisionContainer } from "./VisionStyle";
import pillarImage from "../assets/icons/pillar.png";

function Vision() {
  return (
    <VisionContainer>
      <VisionBanner />

      <div>
        <p>
          We aspire to see vulnerable community become self-sufficient and
          diligent with valued norms and strong social cohesion.
        </p>
        <h3>Our Mission</h3>
        <p>
          To capacitate schools, vulnerable school children, women and youth
          through provision of psychological, educational, social and economic
          empowerment.
        </p>
        <h3>Our Goal</h3>
        <p>
          To Address the integrated psychological, social and economic needs of
          our target groups by 2026/27.
        </p>
        <h3 className="pillars">Pillars</h3>
        <div >
          <div>
            <img src={pillarImage} alt="PillarIcon" />
            <h5>Utubaa</h5>
            <p>
              This program focuses on young and adolescent children. In this
              program, We strive to assist young children in their education.
              Our organization provides school supplies, clothing and shoes as
              well as bags to individual students. In addition, we provide
              financial assistance to the families of these children aimed at
              providing the children with food suppliers and meals.
            </p>
          </div>
          <div>
            <img src={pillarImage} alt="PillarIcon" />
            <h5>Jabduu</h5>
            <p>
              This program focuses on empowering women and men across in
              selected towns and woredas. We provide training in skill and
              techniques for productivity in their small business along with
              providing necessary funding after training to enable them to
              support themselves and their families.
            </p>
          </div>
          <div>
            <img src={pillarImage} alt="PillarIcon" />
            <h5>Santu-Seeraa</h5>
            <p>
              This program focuses on cultural advocacy and representation of
              valuable cultural values and norms. We focus on working with
              members of the community alongside concerned bodies to promote the
              good culture within the society as well as enabling communities to
              uphold their virtues.
            </p>
          </div>
        </div>
      </div>
    </VisionContainer>
  );
}

export default Vision;
