import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { url_prefix } from "../../Constants";
import { convertDate } from "../../Utils";

const SideBarCardContainer = styled.div`
  display: flex;
  cursor: pointer;
  padding-left: 1rem;

  > img {
    width: 10rem;
    transition: scale 200ms ease-in-out;
    &:hover{
        scale: 1.1;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1rem;
    > p:first-of-type {
      font-weight: 500;
      font-size: 1rem;
    }
    > p:nth-of-type(2) {
      font-weight: 400;
      font-size: 0.9rem;
      color: var(--secondary-ascent-color);
    }
  }
`;

function SideBarCard({item,link}) {
    const navigate = useNavigate()
    const handleClick = (link,id)=>{
        navigate(link+'/'+id)
    }
  return (
    /* link should be passed as a prop */
    <SideBarCardContainer onClick={()=>handleClick(link,item?.id)}>
      <img src={url_prefix+item?.image} alt="" />
      <div>
        <p>{item?.title}</p>
        <p>{convertDate(item?.dateCreated)}</p>
      </div>
    </SideBarCardContainer>
  );
}

export default SideBarCard;
