import styled from "styled-components";

export const DonationInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    >div:nth-of-type(2),div:nth-of-type(3),div:nth-of-type(4){
        display: flex;
        >p{
            box-sizing: border-box;
            margin-right: 1rem;
            
        }
        >p:nth-child(2){
            font-weight: 500;
        }
    }
    >h3{
        font-weight: 500;
        font-size: 1.2rem;
        color: var(--secondary-ascent-color);
    }
`;