import styled from "styled-components";

export const FooterContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width:768px){
        grid-template-columns: 1fr;
    }
    background-color: var(--primary-color);
    color: #e4e4e4;
    >div:first-child{
        display: flex;
        flex-direction: column ;
        justify-content: space-evenly;
        align-items: flex-start;
        padding: 0.5rem 1rem;
        >p{
            color:var(--text-alt-color);
            text-align: justify;
        }
        >h3{
            align-self: center;
            font-weight: 500;
        }
    }
    >div:nth-child(2){
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.5rem 1rem;
        color: #e4e4e4;

        >h3,h4{
            font-weight: 500;
        }
        >p{
            color: var(--text-alt-color);
        }
        >form{
            display: flex;
            width: 100%;
            >input{
            height: 3rem;
            width: 100%;
            background-color: transparent;
            border: 1px var(--tertiary-color) solid;
            color:#e4e4e4 ;
            font-size: 1rem;
        }
        >input:focus{
            border: 1px var(--secondary-ascent-color) solid;
            outline:none;
        }
        }
        

    }
`